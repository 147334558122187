<template>
  <div>
    <UserFeedback :target="t('componentNames.examList')" class="end mr-4 mt-1" :position="'is-left'"></UserFeedback>
    <b-field grouped group-multiline class="pb-1">
      <b-field>
        <b-button icon-left="refresh" @click="getTeacherClassrooms" :loading="isLoading">{{t('common.refresh')}}</b-button>
      </b-field>
      <b-field :label="t('common.from')" label-position="on-border">
        <b-datepicker v-model="date.from" icon="calendar-today"></b-datepicker>
      </b-field>
      <b-field :label="t('common.to')" label-position="on-border">
        <b-datepicker v-model="date.to" icon="calendar-today" :min-date="dateFrom"></b-datepicker>
      </b-field>
      <b-field>
        <b-field>
          <b-checkbox v-model="showExamTest" class="mr-5">
            <span class="bold">{{t('classroom.types.examTest')}}</span>
          </b-checkbox>
          <b-checkbox v-model="showExamDigit" class="mr-5">
            <span class="bold">{{t('classroom.types.examMultimedia')}}</span>
          </b-checkbox>
          <b-checkbox v-model="showExamCombo" class="mr-5">
            <span class="bold">{{t('classroom.types.examCombo')}}</span>
          </b-checkbox>
          <b-checkbox v-model="showLecture">
            <span class="bold">{{t('classroom.types.lecture')}}</span>
          </b-checkbox>
        </b-field>
      </b-field>
    </b-field>
    <b-table
      narrowed
      :data="filteredClassrooms"
      striped
      :loading="isLoading"
      default-sort-direction="desc"
      default-sort="starts"
      hoverable
    >
      <b-table-column field="type" sortable :label="t('common.type')" v-slot="props">
        <b-tooltip :label="mGetClassroomTypeName(props.row.type)" position="is-right">
          <b-icon :icon="mGetClassroomTypeIcon(props.row.type)" size="is-medium" type="is-primary"></b-icon>
        </b-tooltip>
      </b-table-column>
      <b-table-column
        field="name"
        searchable
        sortable
        :label="t('classroom.name')"
        v-slot="props"
      >{{ props.row.name }}</b-table-column>
      <b-table-column
        field="starts"
        searchable
        sortable
        :label="t('classroom.start')"
        v-slot="props"
      >{{ mDateTime(props.row.starts) }}</b-table-column>
      <b-table-column
        field="ends"
        searchable
        sortable
        :label="t('classroom.stop')"
        v-slot="props"
      >{{ mDateTime(props.row.ends) }}</b-table-column>
      <b-table-column
        field="lbusers"
        sortable
        :label="t('classroom.numberStudents')"
        v-slot="props"
      >{{ props.row.users.length }}</b-table-column>
      <b-table-column field="tags" searchable sortable :label="t('forms.tags')" v-slot="props">
        <b-taglist>
          <b-tag v-for="tag in props.row.tags" :key="tag" type="is-secondary" size="is-small">
            {{
            tag
            }}
          </b-tag>
        </b-taglist>
      </b-table-column>
      <b-table-column field="option" :label="t('common.actions')" v-slot="props">
        <div class="buttons">
          <b-tooltip :label="t('classroom.previewStudents')">
            <b-button
              icon-left="account-details"
              @click="showUsers(props.row.users)"
              size="is-small"
              :loading="isLoading"
              type="is-warning"
            ></b-button>
          </b-tooltip>

          <b-tooltip :label="t('classroom.edit')">
            <b-button
              v-if="filterClassroom === 2"
              icon-left="file-edit"
              @click="editClassroom(props.row.id)"
              size="is-small"
              type="is-success"
              :loading="isLoading"
            ></b-button>
          </b-tooltip>
          <!-- <b-tooltip label="Usuń zajęcia"><b-button
            icon-left="delete"
            @click="confirmDelete(props.row.id)"
            size="is-small"
            type="is-danger"
          ></b-button></b-tooltip>-->
          <b-tooltip :label="t('classroom.previewExam')">
            <b-button
              v-if="props.row.exam.id"
              icon-left="file-eye"
              @click="showExam(props.row.exam.id, props.row.exam.type)"
              size="is-small"
              :loading="isLoading"
              type="is-info"
            ></b-button>
          </b-tooltip>
          <b-tooltip :label="t('classroom.stopClassroom')">
            <b-button
              v-if="filterClassroom === 2 && props.row.startOnTime && !props.row.stopOnTime"
              icon-left="stop"
              @click="stopClassroom(props.row)"
              size="is-small"
              type="is-danger"
              :loading="isLoading"
            ></b-button>
          </b-tooltip>
          <b-tooltip :label="t('classroom.startClassroom')">
            <b-button
              v-if="filterClassroom === 1 && (!props.row.startOnTime || props.row.stopOnTime)"
              icon-left="play"
              type="is-success"
              @click="startClassroom(props.row)"
              size="is-small"
              :loading="isLoading"
            ></b-button>
          </b-tooltip>
          <b-tooltip :label="t('classroom.goToLecture')">
            <b-button
              v-if="props.row.type === 0 && filterClassroom === 1 && props.row.startOnTime && !props.row.stopOnTime"
              icon-left="door-open"
              type="is-info"
              @click="openLecture(props.row.id)"
              size="is-small"
              :loading="isLoading"
            ></b-button>
          </b-tooltip>
        </div>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>{{t('common.noData')}}</p>
          </div>
        </section>
      </template>
    </b-table>
    <b-modal v-model="isModalActive">
      <div class="modal-content exam-modal">
        <ExamPreview :idExam="idExam" />
      </div>
    </b-modal>
    <b-modal v-model="isModalUsersActive">
      <div class="modal-content exam-modal">
        <StudentPreview :idStudents="usersPreview" />
      </div>
    </b-modal>
    <b-modal full-screen v-model="isModalEditActive" :can-cancel="true">
      <template #default="props">
        <div class="modal-content exam-modal">
          <ClassroomEdit :idClassroom="idClassroom" @close="props.close" @update="updated" />
        </div>
      </template>
    </b-modal>
    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon icon="package-variant" size="is-large"></b-icon>
          </p>
          <p>{{t('common.noData')}}</p>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import { Action } from "../../store/config_actions";
import CommonHelpers from "@/mixins/commons";
import TypesHelpers from "@/mixins/types";
import ExamPreview from "./../exam/examMentor/ExamPreview";
import StudentPreview from "./classroomStudent/ClassroomPreviewStudents";
import ClassroomEdit from "./classroomCreate/ClassroomEdit";
import UserFeedback from '@/components/feedbacks/UserFeedback'

export default {
  name: "ClassroomsMentorList",
  mixins: [CommonHelpers, TypesHelpers],
  components: {
    ExamPreview,
    ClassroomEdit,
    StudentPreview,
    UserFeedback
  },

  props: {
    refreshTrigger: { type: Number, required: true },
    filterClassroom: { type: Number, required: true },
  },

  data: function () {
    return {
      classrooms: [],
      isLoading: false,
      activedExam: null,
      showExamTest: true,
      showExamDigit: true,
      showExamCombo: true,
      showLecture: true,
      isModalActive: false,
      isModalUsersActive: false,
      isModalEditActive: false,
      idClassroom: null,
      idExam: null,
      usersPreview: [],
      date: {
        from: null,
        to: null,
      },
    };
  },

  mounted() {
    //this.getClassrooms();
    //this.getTeacherClassrooms();
    this.date.to = new Date(this.mNowPlusDays(30));
    this.date.from = new Date(this.mNowPlusDays(-10));
    this.getTeacherClassrooms();
  },

  watch: {
    refreshTrigger() {
      this.getTeacherClassrooms();
    },

    dateFrom() {
      if (this.date.to && this.date.from) {
        this.getTeacherClassrooms();
      }
    },

    dateTo() {
      if (this.date.to && this.date.from) {
        this.getTeacherClassrooms();
      }
    },

    nowTime() {

    }
  },

  computed: {
    filteredClassrooms() {
      if (this.classrooms) {
        let filtered = this.classrooms;

        if (!this.showLecture) {
          filtered = filtered.filter((ex) => ex.type !== 0);
        }

        if (!this.showExamTest) {
          filtered = filtered.filter((ex) => ex.type !== 1);
        }

        if (!this.showExamDigit) {
          filtered = filtered.filter((ex) => ex.type !== 2);
        }

        if (!this.showExamCombo) {
          filtered = filtered.filter((ex) => ex.type !== 3)
        }

        if (this.filterClassroom === 0) {
          filtered = filtered.filter((ex) => !this.mBeforeNow(ex.ends));
        } else if (this.filterClassroom === 1) {
          filtered = filtered.filter(
            (ex) =>
              (this.mBeforeNow(ex.ends) && this.mAfterNow(ex.starts)) === true
          );
        } else if (this.filterClassroom === 2) {
          filtered = filtered.filter((ex) => !this.mAfterNow(ex.starts));
        }

        return filtered;
      } else return [];
    },

    dateFrom() {
      return new Date(this.date.from);
    },

    dateTo() {
      return new Date(this.date.to);
    },
  },

  methods: {
    // confirmDelete(id) {
    //   this.$buefy.dialog.confirm({
    //     title: "Usuń zajęcia",
    //     message: "Czy na pewno chcesz usunąć zajęcia?",
    //     onConfirm: () => this.deleteClassroom(id),
    //     type: "is-danger",
    //     hasIcon: true,
    //     cancelText: 'Nie',
    //     confirmText: 'Tak',
    //   });
    // },

    confirmDelete(id) {
      this.$buefy.dialog.confirm({
        title: this.t('classroom.remove'),
        message: this.t('classroom.removeConfirm'),
        onConfirm: () => this.deleteClassroom(id),
        type: "is-danger",
        hasIcon: true,
        cancelText: this.t('common.no'),
        confirmText: this.t('common.yes'),
      });
    },

    editClassroom(id) {
      this.idClassroom = id;
      this.isModalEditActive = !this.isModalEditActive;
    },

    showExam(id, type) {
      if (type === 0) {
        this.idExam = id;
        this.isModalActive = !this.isModalActive;
      } else if (type === 1) {
        this.$router.push({
          name: "examimagepreview",
          params: { idExam: id, isCombo: false },
        });
      } else if (type === 2) {
        this.$router.push({
          name: "examimagepreview",
          params: { idExam: id, isCombo: true },
        });
      }
    },

    stopClassroom(classroom) {
      classroom.startOnTime = false;
      this.updateClassroom(classroom);
    },

    startClassroom(classroom) {
      classroom.startOnTime = true;
      classroom.stopOnTime = false;
      this.updateClassroom(classroom);
    },

    updated() {
      this.getTeacherClassrooms();
    },

    showUsers(users) {
      this.usersPreview = users;
      this.isModalUsersActive = !this.isModalUsersActive;
    },

    openLecture(id) {
      this.$router.push({ name: "opensea", params: { classId: id } });
    },

    deleteClassroom(idClassroom) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOM_REMOVE, idClassroom)
        .then(() => {
          this.mSuccessSnack(this.t('classroom.removed'),);
          this.$emit("deleteClassroom");
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    updateClassroom(classroom) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOM_UPDATE, classroom)
        .then(() => {
          this.mSuccessSnack(this.t('common.changesSaved'));
          this.updated();
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getClassrooms() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOM_GET_ALL)
        .then((payload) => {
          this.classrooms = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getTeacherClassrooms() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOM_GET_TEACHER_CLASS, this.date)
        .then((payload) => {
          this.classrooms = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.exam-modal {
  background-color: white;
}

div.end {
  float: right;
}

.bold {
  font-weight: bold;
}
</style>