<template>
  <div class="card mt-4 ml-5 mr-5" style="min-height: 70vh;">
    <b-tabs :animated="false" v-model="selectTab">
      <b-tab-item :label="t('componentNames.classrooms')" icon="book" headerClass="is-secondary">
        <ClassroomMentorList
          :refreshTrigger="refreshTrigger"
          :filterClassroom="1"
        ></ClassroomMentorList>
      </b-tab-item>
      <b-tab-item :label="t('componentNames.classroomsOngoing')" icon="book-clock">
        <ClassroomMentorList
          :refreshTrigger="refreshTrigger"
          :filterClassroom="2"
        ></ClassroomMentorList>
      </b-tab-item>
      <b-tab-item :label="t('componentNames.classroomsHistory')" icon="book-lock">
        <ClassroomMentorList
          :refreshTrigger="refreshTrigger"
          :filterClassroom="0"
        ></ClassroomMentorList>
      </b-tab-item>
      <b-tab-item :label="t('componentNames.newClassroom')" icon="book-plus">
        <ClassroomCreator @classroomAdded="classroomAdded"></ClassroomCreator>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import ClassroomMentorList from "./ClassroomMentorList";
import ClassroomCreator from "./classroomCreate/ClassroomCreator";

export default {
  name: "ClassroomsMentor",
  mixins: [CommonHelpers],
  components: {
    ClassroomMentorList,
    ClassroomCreator,
  },

  props: {},

  data: function () {
    return {
      refreshTrigger: 0,
      selectTab: 0,
    };
  },

  mounted() {},

  watch: {},

  computed: {},

  methods: {
    classroomAdded() {
      this.refreshTrigger++;
      this.selectTab = 0;
    },
  },
};
</script>

<style scoped>
.card {
  overflow: visible !important;
}
</style>