<template>
  <div class="section">
    <b-table :data="students" striped paginated>
      <b-table-column
        :label="t('common.studentIndex')"
        field="studentIndex"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.studentIndex }}</b-table-column>
      <b-table-column
        :label="t('common.firstName')"
        field="firstName"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.firstName }}</b-table-column>
      <b-table-column
        :label="t('common.lastName')"
        field="lastName"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.lastName }}</b-table-column>
      <b-table-column
        :label="t('common.study')"
        field="studentStudy"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.studentYear }}</b-table-column>
      <b-table-column
        :label="t('common.specialization')"
        field="studentSpec"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.studentSpec }}</b-table-column>
      <b-table-column
        :label="t('common.year')"
        field="studentYear"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.studentYear }}</b-table-column>
      <b-table-column
        :label="t('common.semester')"
        field="studentSemester"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.studentSemester }}</b-table-column>
      <b-table-column
        :label="t('common.course')"
        field="studentCourse"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.studentCourse }}</b-table-column>
      <b-table-column field="studentGroups" searchable sortable :label="t('common.groups')" v-slot="props">
        <b-taglist>
          <b-tag v-for="group in props.row.studentGroups" :key="group" size="is-small">{{ group }}</b-tag>
        </b-taglist>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>{{t('common.noData')}}</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "@/store/config_actions";

export default {
  name: "StudentList",
  mixins: [CommonHelpers],
  components: {},

  props: { idStudents: { type: Array, required: false } },

  data: function () {
    return {
      //students: [],
    };
  },

  mounted() {
    // this.students = [];
    // if (this.idStudents) {
    //   this.idStudents.forEach((id) => {
    //     this.getUser(id);
    //   });
    // }

    this.getStudentsToStorage();
  },

  watch: {
    // idStudents() {
    //   if (this.idStudents) {
    //     this.students = [];
    //     this.idStudents.forEach((id) => {
    //       this.getUser(id);
    //     });
    //   }
    // },
  },

  computed: {
    students() {
      if (this.$store.state.student.students) {
        let filtered = [];
        this.idStudents.forEach((stuId) => {
          let student = this.$store.state.student.students.find(
            (s) => s.id === stuId
          );
          if (student) {
            filtered.push(student);
          }
        });
        return filtered;
      } else return [];
    },
  },

  methods: {
    getStudentsToStorage() {
      if (!this.$store.state.student.students) {
        this.isLoading = true;
        this.$store
          .dispatch(Action.STUDENT_GET_ALL)
          .then(() => {
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
            this.isLoading = false;
          });
      }
    },

    // getUser(id) {
    //   this.isLoading = true;
    //   this.$store
    //     .dispatch(Action.AUTH_GET_USER, id)
    //     .then((payload) => {
    //       this.students.push(payload);
    //       this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       this.mDangerSnack(error.toString());
    //       this.isLoading = false;
    //     });
    // },
  },
};
</script>

<style scoped>
</style>