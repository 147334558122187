<template>
  <div class="section card">
    <p class="subtitle">{{t('componentNames.classroomCreator')}}</p>
    <hr />
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <form>
          <b-field>
            <ValidationProvider rules="required" v-slot="{ errors, valid }">
              <b-field
                :label="t('classroom.type')"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="t(errors[0])"
                grouped group-multiline
              >
                <b-radio-button
                  v-for="option in mClassroomTypes"
                  :key="option.id"
                  v-model="radioButton"
                  :native-value="option"
                  type="is-primary"
                  @input="localClassroom.type = option.id"
                >
                  <b-icon :icon="option.iconName"></b-icon>
                  <span>{{ option.name }}</span>
                </b-radio-button>
              </b-field>
            </ValidationProvider>
          </b-field>
          <b-field>
            <ValidationProvider
              rules="required|max:99"
              v-slot="{ errors, valid }"
            >
              <b-field
                :label="t('classroom.name')"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="t(errors[0], { max: 99 })"
              >
                <b-input
                  type="text"
                  v-model="localClassroom.name"
                  :has-counter="false"
                  required
                ></b-input>
              </b-field>
            </ValidationProvider>
          </b-field>
          <b-field grouped group-multiline>
            <b-field
              :label="t('classroom.datetimeLabel')"
              :message="messageDateTime"
              :type="{
                'is-danger': messageDateTime != null,
                'is-success': messageDateTime == null,
              }"
              grouped group-multiline
              class="mb-4"
            >
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <b-field
                  :label="t('classroom.day')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0])"
                >
                  <b-datepicker
                    v-model="start.date"
                    icon="calendar-today"
                    :min-date="minDate"
                  ></b-datepicker>
                </b-field>
              </ValidationProvider>
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <b-field
                  :label="t('classroom.time')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0])"
                >
                  <b-clockpicker
                    v-model="start.time"
                    icon="clock-time-two-outline"
                  ></b-clockpicker>
                </b-field>
              </ValidationProvider>
            </b-field>

            <b-field>
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <b-field
                  :label="t('classroom.timeLabel')"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0])"
                >
                  <b-numberinput
                    controls-position="compact"
                    controls-alignment="right"
                    placeholder="Czas..."
                    v-model="timeClassroom"
                    max="180"
                    min="0"
                  />
                </b-field>
              </ValidationProvider>
            </b-field>
          </b-field>
          <b-field>
            <b-switch v-model="localClassroom.startOnTime">
                {{ localClassroom.startOnTime ? t('classroom.startOnTime') : t('classroom.dontStartOnTime') }}
            </b-switch>
        </b-field>
          <b-field>
            <b-field :label="t('classroom.addTags')">
              <b-taginput
                v-model="localClassroom.tags"
                ellipsis
                icon="label"
                :placeholder="t('common.add')"
                :aria-close-label="t('common.remove')"
              >
              </b-taginput>
            </b-field>
          </b-field>
          <b-field v-if="currentCourse">
            <b-collapse
              aria-id="selectTeacher"
              class="panel shadow-local"
              animation="slide"
              v-model="teachersIsOpen"
            >
              <div slot="trigger" class="local-panel">
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('clasroom.teacher')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      type="text"
                      v-model="tempTeachername"
                      :has-counter="false"
                      required
                      disabled
                      expanded
                    ></b-input>
                    <b-button aria-controls="selectTeacher" v-if="isAdmin"
                      >{{t('classroom.addTeacher')}}</b-button
                    >
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="section" v-if="isAdmin">
                <ClassroomCreatorTeachers
                  :availableTeachers="currentCourse.teachers"
                  :idTeacher="localClassroom.teacher"
                  @setTeacher="setTeacher"
                />
              </div>
            </b-collapse>
          </b-field>
          <b-field v-if="currentCourse">
            <b-collapse
              aria-id="selectStudents"
              class="panel shadow-local"
              animation="slide"
              v-model="studentsIsOpen"
            >
              <div slot="trigger" class="local-panel">
                <p>{{t('classroom.addStudents')}}</p>
              </div>
              <div class="section">
                <ClassroomCreatorStudents
                  :availableStudents="currentCourse.students"
                  :idStudents="localClassroom.users"
                  @addStudents="addStudents"
                  @removeStudents="removeStudents"
                />
              </div>
            </b-collapse>
          </b-field>
          <b-field v-if="localClassroom.type">
            <b-collapse
              aria-id="selectExam"
              class="panel shadow-local"
              animation="slide"
              v-model="examIsOpen"
            >
              <div slot="trigger" class="local-panel">
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('classroom.exam')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      type="text"
                      v-model="localClassroom.exam.description"
                      :has-counter="false"
                      required
                      disabled
                      expanded
                    ></b-input>
                    <b-button aria-controls="selectExam"
                      >{{t(clasroom.selectExam)}}</b-button
                    >
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="section">
                <ClassroomCreatorExam
                  :typeShow="radioButton.examType"
                  @selectionExam="selectedExam"
                  :idExam="localClassroom.exam.id"
                ></ClassroomCreatorExam>
              </div>
            </b-collapse>
          </b-field>
          <div v-else-if="localClassroom.type === 0">
            <b-collapse
              aria-id="selectDeepZoom"
              class="panel shadow-local"
              animation="slide"
              v-model="deepzoomIsOpen"
            >
              <div slot="trigger" class="local-panel">
                  <b-field
                    :label="t('classroom.deepZooms')"
                    label-position="on-border"
                    :message="messageImages"
                  :type="{
                    'is-danger': messageImages != null,
                    'is-success': messageImages == null,
                  }"
                  >
                    <b-taginput
                      v-model="localClassroom.images"
                      :has-counter="false"
                      required
                      :readonly="true"
                      :closable="false"
                      type="is-primary"
                      expanded
                    ></b-taginput>
                    <b-button aria-controls="selectDeepZoom">
                      {{t('classroom.selectDeepZoom')}}
                    </b-button>
                  </b-field>
              </div>
              <div class="section">
                <ClassroomCreatorDeepzooms
                  @addDeepzooms="addDeepzoom"
                  @removeDeepzooms="removeDeepzoom"
                  @save="deepzoomIsOpen = !deepzoomIsOpen"
                  :idDeepzooms="localClassroom.images"
                ></ClassroomCreatorDeepzooms>
              </div>
            </b-collapse>

            <b-collapse
              aria-id="selectMedia"
              class="panel shadow-local"
              animation="slide"
              v-model="mediaIsOpen"
            >
              <div slot="trigger" class="local-panel">
                <b-field
                  :label="t('classroom.multimedia')"
                  label-position="on-border"
                >
                  <b-taginput
                    v-model="nameMedia"
                    :has-counter="false"
                    required
                    :readonly="true"
                    :closable="false"
                    type="is-primary"
                    expanded
                  ></b-taginput>
                  <b-button aria-controls="selectMedia">
                    {{t('classroom.selectMultimedia')}}
                  </b-button>
                </b-field>
              </div>
              <div class="section">
                <template v-if="isAdmin">
                <ClassroomCreatorMultimedia
                  @addMultimedias="addMedia"
                  @removeMultimedias="removeMedia"
                  @save="mediaIsOpen = !mediaIsOpen"
                  :idMultimedias="localClassroom.media"
                ></ClassroomCreatorMultimedia>
                </template>
                <template v-else>
                  <ClassroomCreatorMultimedia
                  @addMultimedias="addMedia"
                  @removeMultimedias="removeMedia"
                  @save="mediaIsOpen = !mediaIsOpen"
                  :idMultimedias="localClassroom.media"
                  :idOwner="localClassroom.teacher"
                ></ClassroomCreatorMultimedia>
                </template>
              </div>
            </b-collapse>
          </div>

          <hr />
          <div class="buttons">
            <b-button type="is-danger" icon-left="close" @click="close">
              {{t('common.cancel')}}
            </b-button>
            <b-button
              type="is-success"
              icon-left="content-save"
              @click="saveClassroom"
              :disabled="invalid || messageImages || messageDateTime"
            >
              {{t('common.saveChanges')}}
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    <b-loading v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import TypesHelpers from "@/mixins/types";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Action } from "../../../store/config_actions";
import ClassroomCreatorExam from "./ClassroomCreatorExams";
import ClassroomCreatorStudents from "./ClassroomCreatorStudents";
import ClassroomCreatorTeachers from "./ClassroomCreatorTeachers";
import ClassroomCreatorDeepzooms from "./ClassroomCreatorDeepzoom";
import ClassroomCreatorMultimedia from "./ClassroomCreatorMultimedia";
import moment from "moment";

export default {
  name: "ClassroomCreator",
  mixins: [CommonHelpers, TypesHelpers],
  components: {
    ValidationProvider,
    ValidationObserver,
    ClassroomCreatorExam,
    ClassroomCreatorStudents,
    ClassroomCreatorTeachers,
    ClassroomCreatorDeepzooms,
    ClassroomCreatorMultimedia,
  },

  props: { idClassroom: { type: String, required: true } },

  data: function () {
    const today = new Date();
    return {
      isLoading: false,
      localClassroom: {},
      examIsOpen: false,
      studentsIsOpen: false,
      teachersIsOpen: false,
      deepzoomIsOpen: false,
      mediaIsOpen: false,
      tags: [],
      idQ: 1,
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      start: {
        date: undefined,
        time: undefined,
      },
      end: {
        date: undefined,
        time: undefined,
      },
      radioButton: "",
      tempTeachername: null,
      currentCourse: null,
      setCourse: false,
      timeClassroom: 0,
      newClassroom: {
        type: 0,
        name: "",
        starts: "", //date-time
        ends: "", //date-time
        teacher: "",
        users: [],
        exam: {},
        shapeData: "",
        tags: [],
        images: [],
        media: [],
      },
      messageDateTime: null,
      messageImages:null,
      images: [],
      myFiles: [],
      deepZoomConfig: null,
    };
  },

  mounted() {
    if (this.idClassroom) {
      this.getClassrooom();
    } else {
      this.localClassroom = this.newClassroom;
      this.radioButton = this.mGetClassroomType(this.localClassroom.type);
    }
    this.getDeepZoomConfig();
    this.getMyFiles();
  },

  watch: {
    currentTime() {
      if (this.start) {
        this.localClassroom.starts = this.formatDate(this.start);
        if (!this.mBeforeNow(this.localClassroom.starts)) {
          this.messageDateTime = this.t('validation.messageDatetime')//"Data nie może być wcześniejsza niż aktualna";
        } else {
          this.messageDateTime = null;
        }
      }
    },

    currentImages(){
      if(this.newClassroom.type === 0){
        if(this.newClassroom.images.length ===0){
          this.messageImages =  this.t('validation.messageImages')//"Musisz wybrać preparaty do wykładu";
        }else{
          this.messageImages = null;
        }
      }else{
        this.messageImages = null;
      }
    },
  },

  computed: {
    isAdmin() {
      if (this.session && this.session.roles.includes("Admin")) return true;
      else return false;
    },

    isTeacher() {
      if (this.session && this.session.roles.includes("Teacher")) return true;
      else return false;
    },

    currentTime() {
      return this.start.time;
    },

    nameMedia() {
      let names = [];
      this.localClassroom.media.forEach((element) => {
        let media = this.$store.state.media.myFiles.find(
          (m) => m.id === element
        );
        if(media){
        names.push(media.originalFileName);
        }
      });
      return names;
    },

    currentImages(){
      if(this.newClassroom.type === 0){
        return this.newClassroom.images;
      }
      else return []
    },
  },

  methods: {
    backClassrooms() {
      this.$router.go(-1);
    },

    close() {
      this.$emit("close");
    },

    saveClassroom() {

      let timeStarts = this.formatDate(this.start);
      let timeEnds = this.mDatePlusMinutes(
        timeStarts,
        this.timeClassroom
      );

      this.localClassroom.starts = this.mDateTimeRaw(timeStarts);
      this.localClassroom.ends = this.mDateTimeRaw(timeEnds);

      if (!this.mBeforeNow(this.localClassroom.starts)) {
        this.mDangerSnack(this.t('validation.messageDatetime'));
      } else {
        this.updateClassroom();
      }
    },

    formatDate(dateToFormat) {
      var dateString = this.mDate(dateToFormat.date);
      var timeString = this.mTime(dateToFormat.time);
      return this.mDateTimeRaw2(dateString.concat(" ", timeString));
    },

    formatDateToForms(dateString) {
      var dateF = new Date(this.mDate(dateString));
      var timeF = new Date();
      timeF.setHours(this.mHours(dateString)),
        timeF.setMinutes(this.mMinutes(dateString));
      var dateFormat = {
        date: dateF,
        time: timeF,
      };

      return dateFormat;
    },

    selectedExam(exam) {
      this.localClassroom.exam = exam;
    },

    setTeacher(teacher) {
      if (teacher.id) {
        this.tempTeachername = teacher.firstName.concat(" ", teacher.lastName);
        this.localClassroom.teacher = teacher.id;
      } else {
        this.tempTeachername = null;
        this.localClassroom.teacher = "";
      }
    },

    addStudents(idStudents) {
      idStudents.forEach((element) => {
        this.localClassroom.users.push(element);
      });
    },

    removeStudents(idStudents) {
      idStudents.forEach((element) => {
        this.localClassroom.users.splice(
          this.localClassroom.users.indexOf(element),
          1
        );
      });
    },

    addDeepzoom(idDeepzooms) {
      idDeepzooms.forEach((element) => {
        if (!this.localClassroom.images.includes(element)) {
          this.localClassroom.images.push(element);
        }
      });
    },

    removeDeepzoom(idDeepzooms) {
      idDeepzooms.forEach((element) => {
        if (this.localClassroom.images.includes(element)) {
          this.localClassroom.images.splice(
            this.localClassroom.images.indexOf(element),
            1
          );
        }
      });
    },

    addMedia(idMedias) {
      idMedias.forEach((element) => {
        if (!this.localClassroom.media.includes(element)) {
          this.localClassroom.media.push(element);
        }
      });
    },

    removeMedia(idMedias) {
      idMedias.forEach((element) => {
        if (this.localClassroom.media.includes(element)) {
          this.localClassroom.media.splice(
            this.localClassroom.media.indexOf(element),
            1
          );
        }
      });
    },

    // addNewClassroom() {
    //   this.isLoading = true;
    //   this.$store
    //     .dispatch(Action.CLASSROOM_ADD, this.localClassroom)
    //     .then(() => {
    //       this.$router.push({ name: "classroomsmentor" });
    //     })
    //     .catch((error) => {
    //       this.mDangerSnack(error.toString());
    //       this.isLoading = false;
    //     });
    // },

    updateClassroom() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOM_UPDATE, this.localClassroom)
        .then(() => {
          this.mSuccessSnack(this.t('common.changesSaved'));
          this.$emit("update");
          this.close();
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
        });
    },

    getCourse(id) {
      this.$store
        .dispatch(Action.COURSE_GET, id)
        .then((payload) => {
          this.currentCourse = payload;
          this.setCourse = true;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getClassrooom() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOM_GET_ONE_BY_ID, this.idClassroom)
        .then((payload) => {
          if (!payload.media) {
            payload.media = [];
          }
          this.localClassroom = payload;
          this.radioButton = this.mGetClassroomType(this.localClassroom.type);
          this.start = this.formatDateToForms(this.localClassroom.starts);
          this.timeClassroom = moment(this.localClassroom.ends).diff(
            this.localClassroom.starts,
            "minutes"
          );
          //this.minDate = new Date(this.localClassroom.starts);
          if (payload.teacher) {
            this.getTeacher(payload.teacher);
          }
          if (payload.course) {
            this.getCourse(payload.course);
          } else {
            this.setCourse = true;
          }
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getTeacher(id) {
      this.$store
        .dispatch(Action.AUTH_GET_USER, id)
        .then((payload) => {
          if (payload) {
            var firstname = payload.firstName;
            this.tempTeachername = firstname.concat(" ", payload.lastName);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getDeepZoomConfig() {
      this.isLoading = true;
      if (this.$store.state.deepzoom.config) {
        this.deepZoomConfig = this.$store.state.deepzoom.config;
        this.images = this.deepZoomConfig.images;
      } else {
        this.$store
          .dispatch(Action.DEEPZOOM_CONFIG_GET)
          .then((payload) => {
            this.deepZoomConfig = payload;
            this.images = payload.images;
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
          });
      }
    },

    getMyFiles() {
      this.isLoading = true;
      if (this.$store.state.media.myFiles) {
        this.myFiles = this.$store.state.media.myFiles;
        this.isLoading = false;
      } else {
        this.$store
          .dispatch(Action.MEDIA_GET_MY)
          .then((payload) => {
            this.myFiles = payload;
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.local-panel{
  background-color: #ededed;
    color: #363636;
    font-weight: 700;
    line-height: 1.25;
    padding: 0.75em 1em;
}

.shadow-local{
  box-shadow: none !important;
  border-radius: 0px !important;
  border: 1px solid #ededed;
}
</style>
