<template>
  <div class="home">
    <ClassroomMentor />
  </div>
</template>

<script>
// @ is an alias to /src
import ClassroomMentor from '@/components/classroom/ClassroomMentor'

export default {
  name: 'ClassroomsMentorView',
  components: {
    ClassroomMentor
  }
}
</script>
